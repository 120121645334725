import React, { Fragment, useState } from "react";
import { Container, Navbar, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/easyshop.png";
import Bars from "../../assets/images/bars.png";

import MegaMenuAll from "../home/MegaMenuAll";

const NavMenuDesktop = () => {
  const [isActive, setActive] = useState("false");

  const menuBarClickHandler = () => {
    setActive(!isActive);
  };

  return (
    <Fragment>
      <div className="TopSectionDown">
        <Navbar fixed="top" className="navbar" bg="light">
          <Container
            fluid={"true"}
            className="fixed-top shadow-sm p-2 mb-0 bg-white"
          >
            <Row>
              <Col lg={4} md={4} sm={12} xs={12}>
                <img
                  onClick={menuBarClickHandler}
                  className="bar-img"
                  src={Bars}
                  alt="img"
                />

                <Link to="/">
                  <img className="nav-logo" src={Logo} alt="temp" />
                </Link>
              </Col>
              <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>
                <div className="input-group w-100">
                  <input type="text" className="form-control" />
                  <Button type="button" className="btn site-btn">
                    <i className="fa fa-search"></i>
                  </Button>
                </div>
              </Col>
              <Col className="p-1 mt-1" lg={4} md={4} sm={12} xs={12}>
                <Link to="/favourite" className="btn">
                  <i className="fa h4 fa-heart"></i>
                  <sup>
                    <span className="badge text-white bg-danger">3</span>
                  </sup>
                </Link>
                <Link to="/notification" className="btn">
                  <i className="fa h4 fa-bell"></i>
                  <sup>
                    <span className="badge text-white bg-danger">5</span>
                  </sup>
                </Link>
                <Link to="/" className="btn">
                  <i className="fa h4 fa-mobile-alt"></i>
                </Link>
                <Link to="/login" className="h4 btn">
                  LOGIN
                </Link>
                <Button className="cart-btn">
                  <i className="fa fa-shopping-cart"></i> 3 items
                </Button>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
      <div className={!isActive ? "sideNavOpen" : "sideNavClose"}>
        <MegaMenuAll />
      </div>
      <div
        className={!isActive ? "ContentOverlayOpen" : "ContentOverlayClose"}
      ></div>
    </Fragment>
  );
};

export default NavMenuDesktop;
