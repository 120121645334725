import React, { Fragment } from "react";
import { useState } from "react";
import { Container, Navbar, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/easyshop.png";
import MegaMenuMobile from "../home/MegaMenuMobile";

const NavMenuMobile = () => {

  const [isActive, setActive] = useState("false");

  const menuBarClickHandler  = () => {
    setActive(!isActive);
  }

  return (
    <Fragment>
      <div className="TopSectionDown">
        <Container
          fluid={"true"}
          className="fixed-top shadow-sm p-2 mb-0 bg-white"
        >
          <Row>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Button onClick={menuBarClickHandler} className="btn">
                <i className="fa fa-bars"></i>
              </Button>
              <Link to="/">
                <img className="nav-logo" src={Logo} />
              </Link>
              <Button className="cart-btn">
                <i className="fa fa-shopping-cart"></i> 3 items
              </Button>
            </Col>
          </Row>
        </Container>

        <div className={!isActive ? "sideNavOpen" : "sideNavClose"}>
          <MegaMenuMobile />
        </div>
        <div className={!isActive ? "ContentOverlayOpen" : "ContentOverlayClose"}></div>
      </div>
    </Fragment>
  );
};

export default NavMenuMobile;
