import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import ContactPage from "../pages/ContactPage";
import Homepage from "../pages/Homepage";
import UserLoginPage from "../pages/UserLoginPage";
import PurchasePage from "../pages/PurchasePage";
import PrivacyPage from "../pages/PrivacyPage";
import RefundPage from "../pages/RefundPage";
import ProductDetailsPage from "../pages/ProductDetailsPage";
import NotificationPage from "../pages/NotificationPage";
import FavouritePage from "../pages/FavouritePage";

const AppRoute = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/login" component={UserLoginPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/purchase" component={PurchasePage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/refund" component={RefundPage} />
        <Route exact path="/productdetails" component={ProductDetailsPage} />
        <Route exact path="/notification" component={NotificationPage} />
        <Route exact path="/favourite" component={FavouritePage} />
      </Switch>
    </Fragment>
  );
};

export default AppRoute;
