import React, { Fragment } from "react";
import { Accordion } from "react-bootstrap";

const MegaMenuAll = () => {
  return (
    <Fragment>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <img
              alt="tmp"
              className="accordionMenuIconAll"
              src="https://cdn-icons-png.flaticon.com/128/739/739249.png"
            />
            &nbsp; Men's Clothing
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 1
                  </a>
                </li>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 2
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <img
              alt="tmp"
              className="accordionMenuIconAll"
              src="https://cdn-icons-png.flaticon.com/128/739/739249.png"
            />
            &nbsp; Men's Clothing
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 1
                  </a>
                </li>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 2
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <img
              alt="tmp"
              className="accordionMenuIconAll"
              src="https://cdn-icons-png.flaticon.com/128/739/739249.png"
            />
            &nbsp; Men's Clothing
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 1
                  </a>
                </li>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 2
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <img
              alt="tmp"
              className="accordionMenuIconAll"
              src="https://cdn-icons-png.flaticon.com/128/739/739249.png"
            />
            &nbsp; Men's Clothing
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 1
                  </a>
                </li>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 2
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <img
              alt="tmp"
              className="accordionMenuIconAll"
              src="https://cdn-icons-png.flaticon.com/128/739/739249.png"
            />
            &nbsp; Men's Clothing
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 1
                  </a>
                </li>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 2
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <img
              alt="tmp"
              className="accordionMenuIconAll"
              src="https://cdn-icons-png.flaticon.com/128/739/739249.png"
            />
            &nbsp; Men's Clothing
          </Accordion.Header>
          <Accordion.Body>
            <div>
              <ul>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 1
                  </a>
                </li>
                <li>
                  <a href="#" className="accordionItemAll">
                    {" "}
                    Mans Tshirt 2
                  </a>
                </li>
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Fragment>
  )
}

export default MegaMenuAll;